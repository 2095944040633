export interface City {
    lat: number;
    lng: number;
}

export interface Country {
    name: string;
    capital: Record<string, City>;
}

export interface CountryListItem {
    label: string;
    value: string;
}

export interface CityListItem {
    label: string;
    value: string;
}

export function getCountriesList(): CountryListItem[] {
    const countriesList: CountryListItem[] = [];

    for (const countryCode in LOCATION_DATA) {
        if (LOCATION_DATA.hasOwnProperty(countryCode)) {
            countriesList.push({
                label: LOCATION_DATA[countryCode]?.name,
                value: countryCode
            });
        }
    }

    // Sort the countries list alphabetically by the label (country name)

    countriesList.sort((a, b) => a.label.localeCompare(b.label));

    return countriesList;
}

export function getCityNamesByCountryCode(
    countryCode: string
): CityListItem[] {
    const result: CityListItem[] = [];

    // Check if the country code exists in the data
    if (LOCATION_DATA.hasOwnProperty(countryCode)) {
        const capital = LOCATION_DATA[countryCode].capital;

        for (const cityName in capital) {
            if (capital.hasOwnProperty(cityName)) {
                result.push({
                    label: cityName,
                    value: cityName
                });
            }
        }
    }

    // Sort the result alphabetically by the label (city name)
    result.sort((a, b) => a.label.localeCompare(b.label));

    return result;
}

export function getCountryCapital(
    countryCode: string
) : {
    [capitalName: string]: {
        lat: number;
        lng: number;
    };
} | null {
    if (LOCATION_DATA.hasOwnProperty(countryCode)) {
        const country = LOCATION_DATA[countryCode];
        //const capitalName = Object.keys(country.capital)[0];
        return country.capital;
    }
    return null
}

export function getCityLatLng(
    countryCode: string,
    cityName: string
): { lat: number; lng: number } | null {
    // Check if the country code exists in the data
    if (LOCATION_DATA.hasOwnProperty(countryCode)) {
        const country = LOCATION_DATA[countryCode];

        // Check if the city exists in the country's data
        if (country.capital.hasOwnProperty(cityName)) {
            return country.capital[cityName];
        }
    }

    // Return null if the country code or city name is not found
    return null;
}

export const LOCATION_DATA = {
    "JP": {
        "name": "Japan",
        "capital": {
            "Tokyo": {
                "lat": 35.6897,
                "lng": 139.6922
            }
        }
    },
    "ID": {
        "name": "Indonesia",
        "capital": {
            "Jakarta": {
                "lat": -6.175,
                "lng": 106.8275
            }
        }
    },
    "IN": {
        "name": "India",
        "capital": {
            "Delhi": {
                "lat": 28.61,
                "lng": 77.23
            }
        }
    },
    "CN": {
        "name": "China",
        "capital": {
            "Guangzhou": {
                "lat": 23.13,
                "lng": 113.26
            }
        }
    },
    "PH": {
        "name": "Philippines",
        "capital": {
            "Manila": {
                "lat": 14.5958,
                "lng": 120.9772
            }
        }
    },
    "BR": {
        "name": "Brazil",
        "capital": {
            "São Paulo": {
                "lat": -23.55,
                "lng": -46.6333
            }
        }
    },
    "KR": {
        "name": "Korea, South",
        "capital": {
            "Seoul": {
                "lat": 37.56,
                "lng": 126.99
            }
        }
    },
    "MX": {
        "name": "Mexico",
        "capital": {
            "Mexico City": {
                "lat": 19.4333,
                "lng": -99.1333
            }
        }
    },
    "EG": {
        "name": "Egypt",
        "capital": {
            "Cairo": {
                "lat": 30.0444,
                "lng": 31.2358
            }
        }
    },
    "US": {
        "name": "United States",
        "capital": {
            "New York": {
                "lat": 40.6943,
                "lng": -73.9249
            }
        }
    },
    "BD": {
        "name": "Bangladesh",
        "capital": {
            "Dhaka": {
                "lat": 23.7639,
                "lng": 90.3889
            }
        }
    },
    "TH": {
        "name": "Thailand",
        "capital": {
            "Bangkok": {
                "lat": 13.7525,
                "lng": 100.4942
            }
        }
    },
    "RU": {
        "name": "Russia",
        "capital": {
            "Moscow": {
                "lat": 55.7558,
                "lng": 37.6172
            }
        }
    },
    "AR": {
        "name": "Argentina",
        "capital": {
            "Buenos Aires": {
                "lat": -34.6033,
                "lng": -58.3817
            }
        }
    },
    "NG": {
        "name": "Nigeria",
        "capital": {
            "Lagos": {
                "lat": 6.455,
                "lng": 3.3841
            }
        }
    },
    "TR": {
        "name": "Turkey",
        "capital": {
            "Istanbul": {
                "lat": 41.0136,
                "lng": 28.955
            }
        }
    },
    "PK": {
        "name": "Pakistan",
        "capital": {
            "Islamabad": {
                "lat": 33.72,
                "lng": 73.04
            }
        }
    },
    "VN": {
        "name": "Vietnam",
        "capital": {
            "Ho Chi Minh City": {
                "lat": 10.7756,
                "lng": 106.7019
            }
        }
    },
    "IR": {
        "name": "Iran",
        "capital": {
            "Tehran": {
                "lat": 35.6892,
                "lng": 51.3889
            }
        }
    },
    "CD": {
        "name": "Congo (Kinshasa)",
        "capital": {
            "Kinshasa": {
                "lat": -4.3219,
                "lng": 15.3119
            }
        }
    },
    "GB": {
        "name": "United Kingdom",
        "capital": {
            "London": {
                "lat": 51.5072,
                "lng": -0.1275
            }
        }
    },
    "FR": {
        "name": "France",
        "capital": {
            "Paris": {
                "lat": 48.8567,
                "lng": 2.3522
            }
        }
    },
    "PE": {
        "name": "Peru",
        "capital": {
            "Lima": {
                "lat": -12.06,
                "lng": -77.0375
            }
        }
    },
    "AO": {
        "name": "Angola",
        "capital": {
            "Luanda": {
                "lat": -8.8383,
                "lng": 13.2344
            }
        }
    },
    "MY": {
        "name": "Malaysia",
        "capital": {
            "Kuala Lumpur": {
                "lat": 3.1478,
                "lng": 101.6953
            }
        }
    },
    "ZA": {
        "name": "South Africa",
        "capital": {
            "Johannesburg": {
                "lat": -26.2044,
                "lng": 28.0456
            }
        }
    },
    "CO": {
        "name": "Colombia",
        "capital": {
            "Bogotá": {
                "lat": 4.7111,
                "lng": -74.0722
            }
        }
    },
    "TZ": {
        "name": "Tanzania",
        "capital": {
            "Dar es Salaam": {
                "lat": -6.8161,
                "lng": 39.2803
            }
        }
    },
    "SD": {
        "name": "Sudan",
        "capital": {
            "Khartoum": {
                "lat": 15.6,
                "lng": 32.5
            }
        }
    },
    "HK": {
        "name": "Hong Kong",
        "capital": {
            "Hong Kong": {
                "lat": 22.3,
                "lng": 114.2
            }
        }
    },
    "SA": {
        "name": "Saudi Arabia",
        "capital": {
            "Riyadh": {
                "lat": 24.6333,
                "lng": 46.7167
            }
        }
    },
    "CL": {
        "name": "Chile",
        "capital": {
            "Santiago": {
                "lat": -33.4372,
                "lng": -70.6506
            }
        }
    },
    "ES": {
        "name": "Spain",
        "capital": {
            "Madrid": {
                "lat": 40.4169,
                "lng": -3.7033
            }
        }
    },
    "IQ": {
        "name": "Iraq",
        "capital": {
            "Baghdad": {
                "lat": 33.3153,
                "lng": 44.3661
            }
        }
    },
    "SG": {
        "name": "Singapore",
        "capital": {
            "Singapore": {
                "lat": 1.3,
                "lng": 103.8
            }
        }
    },
    "CM": {
        "name": "Cameroon",
        "capital": {
            "Douala": {
                "lat": 4.05,
                "lng": 9.7
            }
        }
    },
    "CA": {
        "name": "Canada",
        "capital": {
            "Toronto": {
                "lat": 43.7417,
                "lng": -79.3733
            }
        }
    },
    "KE": {
        "name": "Kenya",
        "capital": {
            "Nairobi": {
                "lat": -1.2864,
                "lng": 36.8172
            }
        }
    },
    "MM": {
        "name": "Burma",
        "capital": {
            "Rangoon": {
                "lat": 16.795,
                "lng": 96.16
            }
        }
    },
    "AU": {
        "name": "Australia",
        "capital": {
            "Melbourne": {
                "lat": -37.8142,
                "lng": 144.9631
            }
        }
    },
    "CI": {
        "name": "Côte d’Ivoire",
        "capital": {
            "Abidjan": {
                "lat": 5.3167,
                "lng": -4.0333
            }
        }
    },
    "DE": {
        "name": "Germany",
        "capital": {
            "Berlin": {
                "lat": 52.52,
                "lng": 13.405
            }
        }
    },
    "AF": {
        "name": "Afghanistan",
        "capital": {
            "Kabul": {
                "lat": 34.5253,
                "lng": 69.1783
            }
        }
    },
    "ML": {
        "name": "Mali",
        "capital": {
            "Bamako": {
                "lat": 12.6392,
                "lng": -8.0028
            }
        }
    },
    "JO": {
        "name": "Jordan",
        "capital": {
            "Amman": {
                "lat": 31.9497,
                "lng": 35.9328
            }
        }
    },
    "MA": {
        "name": "Morocco",
        "capital": {
            "Casablanca": {
                "lat": 33.5333,
                "lng": -7.5833
            }
        }
    },
    "GH": {
        "name": "Ghana",
        "capital": {
            "Kumasi": {
                "lat": 6.7,
                "lng": -1.625
            }
        }
    },
    "DZ": {
        "name": "Algeria",
        "capital": {
            "Algiers": {
                "lat": 36.7539,
                "lng": 3.0589
            }
        }
    },
    "AE": {
        "name": "United Arab Emirates",
        "capital": {
            "Dubai": {
                "lat": 25.2631,
                "lng": 55.2972
            }
        }
    },
    "BO": {
        "name": "Bolivia",
        "capital": {
            "Santa Cruz de la Sierra": {
                "lat": -17.7892,
                "lng": -63.1975
            }
        }
    },
    "GR": {
        "name": "Greece",
        "capital": {
            "Athens": {
                "lat": 37.9842,
                "lng": 23.7281
            }
        }
    },
    "ET": {
        "name": "Ethiopia",
        "capital": {
            "Addis Ababa": {
                "lat": 9.03,
                "lng": 38.74
            }
        }
    },
    "TW": {
        "name": "Taiwan",
        "capital": {
            "Taichung": {
                "lat": 24.1439,
                "lng": 120.6794
            }
        }
    },
    "GT": {
        "name": "Guatemala",
        "capital": {
            "Guatemala City": {
                "lat": 14.6133,
                "lng": -90.5353
            }
        }
    },
    "KW": {
        "name": "Kuwait",
        "capital": {
            "Kuwait City": {
                "lat": 29.3697,
                "lng": 47.9783
            }
        }
    },
    "HU": {
        "name": "Hungary",
        "capital": {
            "Budapest": {
                "lat": 47.4925,
                "lng": 19.0514
            }
        }
    },
    "YE": {
        "name": "Yemen",
        "capital": {
            "Sanaa": {
                "lat": 15.3483,
                "lng": 44.2064
            }
        }
    },
    "UZ": {
        "name": "Uzbekistan",
        "capital": {
            "Tashkent": {
                "lat": 41.3111,
                "lng": 69.2797
            }
        }
    },
    "UA": {
        "name": "Ukraine",
        "capital": {
            "Kyiv": {
                "lat": 50.45,
                "lng": 30.5233
            }
        }
    },
    "KP": {
        "name": "Korea, North",
        "capital": {
            "Pyongyang": {
                "lat": 39.0167,
                "lng": 125.7475
            }
        }
    },
    "IT": {
        "name": "Italy",
        "capital": {
            "Rome": {
                "lat": 41.8933,
                "lng": 12.4828
            }
        }
    },
    "EC": {
        "name": "Ecuador",
        "capital": {
            "Guayaquil": {
                "lat": -2.19,
                "lng": -79.8875
            }
        }
    },
    "SO": {
        "name": "Somalia",
        "capital": {
            "Mogadishu": {
                "lat": 2.0392,
                "lng": 45.3419
            }
        }
    },
    "SY": {
        "name": "Syria",
        "capital": {
            "Damascus": {
                "lat": 33.5131,
                "lng": 36.2919
            }
        }
    },
    "ZM": {
        "name": "Zambia",
        "capital": {
            "Lusaka": {
                "lat": -15.4167,
                "lng": 28.2833
            }
        }
    },
    "BF": {
        "name": "Burkina Faso",
        "capital": {
            "Ouagadougou": {
                "lat": 12.3686,
                "lng": -1.5275
            }
        }
    },
    "LB": {
        "name": "Lebanon",
        "capital": {
            "Beirut": {
                "lat": 33.8983,
                "lng": 35.5057
            }
        }
    },
    "RO": {
        "name": "Romania",
        "capital": {
            "Bucharest": {
                "lat": 44.4325,
                "lng": 26.1039
            }
        }
    },
    "LK": {
        "name": "Sri Lanka",
        "capital": {
            "Colombo": {
                "lat": 6.9344,
                "lng": 79.8428
            }
        }
    },
    "AZ": {
        "name": "Azerbaijan",
        "capital": {
            "Baku": {
                "lat": 40.3953,
                "lng": 49.8822
            }
        }
    },
    "MG": {
        "name": "Madagascar",
        "capital": {
            "Antananarivo": {
                "lat": -18.91,
                "lng": 47.525
            }
        }
    },
    "VE": {
        "name": "Venezuela",
        "capital": {
            "Caracas": {
                "lat": 10.4806,
                "lng": -66.9036
            }
        }
    },
    "AT": {
        "name": "Austria",
        "capital": {
            "Vienna": {
                "lat": 48.2083,
                "lng": 16.3725
            }
        }
    },
    "ZW": {
        "name": "Zimbabwe",
        "capital": {
            "Harare": {
                "lat": -17.8292,
                "lng": 31.0522
            }
        }
    },
    "KH": {
        "name": "Cambodia",
        "capital": {
            "Phnom Penh": {
                "lat": 11.5694,
                "lng": 104.9211
            }
        }
    },
    "SE": {
        "name": "Sweden",
        "capital": {
            "Stockholm": {
                "lat": 59.3294,
                "lng": 18.0686
            }
        }
    },
    "CU": {
        "name": "Cuba",
        "capital": {
            "Havana": {
                "lat": 23.1367,
                "lng": -82.3589
            }
        }
    },
    "BY": {
        "name": "Belarus",
        "capital": {
            "Minsk": {
                "lat": 53.9,
                "lng": 27.5667
            }
        }
    },
    "NL": {
        "name": "Netherlands",
        "capital": {
            "Tilburg": {
                "lat": 51.55,
                "lng": 5.0833
            }
        }
    },
    "KZ": {
        "name": "Kazakhstan",
        "capital": {
            "Almaty": {
                "lat": 43.24,
                "lng": 76.915
            }
        }
    },
    "MW": {
        "name": "Malawi",
        "capital": {
            "Blantyre": {
                "lat": -15.7861,
                "lng": 35.0058
            }
        }
    },
    "PL": {
        "name": "Poland",
        "capital": {
            "Warsaw": {
                "lat": 52.23,
                "lng": 21.0111
            }
        }
    },
    "PR": {
        "name": "Puerto Rico",
        "capital": {
            "San Juan": {
                "lat": 18.3985,
                "lng": -66.061
            }
        }
    },
    "CG": {
        "name": "Congo (Brazzaville)",
        "capital": {
            "Brazzaville": {
                "lat": -4.2694,
                "lng": 15.2711
            }
        }
    },
    "OM": {
        "name": "Oman",
        "capital": {
            "Masqaţ": {
                "lat": 23.5889,
                "lng": 58.4083
            }
        }
    },
    "UY": {
        "name": "Uruguay",
        "capital": {
            "Montevideo": {
                "lat": -34.8836,
                "lng": -56.1819
            }
        }
    },
    "HN": {
        "name": "Honduras",
        "capital": {
            "Tegucigalpa": {
                "lat": 14.1058,
                "lng": -87.2047
            }
        }
    },
    "UG": {
        "name": "Uganda",
        "capital": {
            "Kampala": {
                "lat": 0.3136,
                "lng": 32.5811
            }
        }
    },
    "GN": {
        "name": "Guinea",
        "capital": {
            "Conakry": {
                "lat": 9.5092,
                "lng": -13.7122
            }
        }
    },
    "BG": {
        "name": "Bulgaria",
        "capital": {
            "Sofia": {
                "lat": 42.7,
                "lng": 23.33
            }
        }
    },
    "CR": {
        "name": "Costa Rica",
        "capital": {
            "San José": {
                "lat": 9.9325,
                "lng": -84.08
            }
        }
    },
    "RW": {
        "name": "Rwanda",
        "capital": {
            "Kigali": {
                "lat": -1.9439,
                "lng": 30.0594
            }
        }
    },
    "PA": {
        "name": "Panama",
        "capital": {
            "Panama City": {
                "lat": 8.9833,
                "lng": -79.5167
            }
        }
    },
    "SN": {
        "name": "Senegal",
        "capital": {
            "Dakar": {
                "lat": 14.6928,
                "lng": -17.4467
            }
        }
    },
    "MN": {
        "name": "Mongolia",
        "capital": {
            "Ulaanbaatar": {
                "lat": 47.9203,
                "lng": 106.9172
            }
        }
    },
    "IL": {
        "name": "Israel",
        "capital": {
            "Tel Aviv-Yafo": {
                "lat": 32.08,
                "lng": 34.78
            }
        }
    },
    "DK": {
        "name": "Denmark",
        "capital": {
            "Copenhagen": {
                "lat": 55.6761,
                "lng": 12.5683
            }
        }
    },
    "FI": {
        "name": "Finland",
        "capital": {
            "Helsinki": {
                "lat": 60.1708,
                "lng": 24.9375
            }
        }
    },
    "CZ": {
        "name": "Czechia",
        "capital": {
            "Prague": {
                "lat": 50.0875,
                "lng": 14.4214
            }
        }
    },
    "NZ": {
        "name": "New Zealand",
        "capital": {
            "Auckland": {
                "lat": -36.8406,
                "lng": 174.74
            }
        }
    },
    "DO": {
        "name": "Dominican Republic",
        "capital": {
            "Santiago": {
                "lat": 19.4572,
                "lng": -70.6889
            }
        }
    },
    "PT": {
        "name": "Portugal",
        "capital": {
            "Porto": {
                "lat": 41.1621,
                "lng": -8.622
            }
        }
    },
    "IE": {
        "name": "Ireland",
        "capital": {
            "Dublin": {
                "lat": 53.35,
                "lng": -6.2603
            }
        }
    },
    "BE": {
        "name": "Belgium",
        "capital": {
            "Brussels": {
                "lat": 50.8467,
                "lng": 4.3525
            }
        }
    },
    "RS": {
        "name": "Serbia",
        "capital": {
            "Belgrade": {
                "lat": 44.82,
                "lng": 20.46
            }
        }
    },
    "QA": {
        "name": "Qatar",
        "capital": {
            "Doha": {
                "lat": 25.2867,
                "lng": 51.5333
            }
        }
    },
    "LY": {
        "name": "Libya",
        "capital": {
            "Tripoli": {
                "lat": 32.8872,
                "lng": 13.1914
            }
        }
    },
    "BI": {
        "name": "Burundi",
        "capital": {
            "Bujumbura": {
                "lat": -3.3833,
                "lng": 29.3667
            }
        }
    },
    "MZ": {
        "name": "Mozambique",
        "capital": {
            "Maputo": {
                "lat": -25.9667,
                "lng": 32.5833
            }
        }
    },
    "KG": {
        "name": "Kyrgyzstan",
        "capital": {
            "Bishkek": {
                "lat": 42.8747,
                "lng": 74.6122
            }
        }
    },
    "GE": {
        "name": "Georgia",
        "capital": {
            "Tbilisi": {
                "lat": 41.7225,
                "lng": 44.7925
            }
        }
    },
    "TD": {
        "name": "Chad",
        "capital": {
            "N’Djamena": {
                "lat": 12.11,
                "lng": 15.05
            }
        }
    },
    "MR": {
        "name": "Mauritania",
        "capital": {
            "Nouakchott": {
                "lat": 18.0858,
                "lng": -15.9785
            }
        }
    },
    "AM": {
        "name": "Armenia",
        "capital": {
            "Yerevan": {
                "lat": 40.1814,
                "lng": 44.5144
            }
        }
    },
    "NO": {
        "name": "Norway",
        "capital": {
            "Oslo": {
                "lat": 59.9133,
                "lng": 10.7389
            }
        }
    },
    "NI": {
        "name": "Nicaragua",
        "capital": {
            "Managua": {
                "lat": 12.1364,
                "lng": -86.2514
            }
        }
    },
    "TM": {
        "name": "Turkmenistan",
        "capital": {
            "Ashgabat": {
                "lat": 37.9375,
                "lng": 58.38
            }
        }
    },
    "NE": {
        "name": "Niger",
        "capital": {
            "Niamey": {
                "lat": 13.515,
                "lng": 2.1175
            }
        }
    },
    "LR": {
        "name": "Liberia",
        "capital": {
            "Monrovia": {
                "lat": 6.3133,
                "lng": -10.8014
            }
        }
    },
    "HT": {
        "name": "Haiti",
        "capital": {
            "Port-au-Prince": {
                "lat": 18.5333,
                "lng": -72.3333
            }
        }
    },
    "ER": {
        "name": "Eritrea",
        "capital": {
            "Asmara": {
                "lat": 15.3228,
                "lng": 38.925
            }
        }
    },
    "SL": {
        "name": "Sierra Leone",
        "capital": {
            "Freetown": {
                "lat": 8.4844,
                "lng": -13.2344
            }
        }
    },
    "LA": {
        "name": "Laos",
        "capital": {
            "Vientiane": {
                "lat": 17.98,
                "lng": 102.63
            }
        }
    },
    "LV": {
        "name": "Latvia",
        "capital": {
            "Riga": {
                "lat": 56.9489,
                "lng": 24.1064
            }
        }
    },
    "CF": {
        "name": "Central African Republic",
        "capital": {
            "Bangui": {
                "lat": 4.3733,
                "lng": 18.5628
            }
        }
    },
    "TJ": {
        "name": "Tajikistan",
        "capital": {
            "Dushanbe": {
                "lat": 38.5367,
                "lng": 68.78
            }
        }
    },
    "NP": {
        "name": "Nepal",
        "capital": {
            "Kathmandu": {
                "lat": 27.71,
                "lng": 85.32
            }
        }
    },
    "GA": {
        "name": "Gabon",
        "capital": {
            "Libreville": {
                "lat": 0.3903,
                "lng": 9.4542
            }
        }
    },
    "HR": {
        "name": "Croatia",
        "capital": {
            "Zagreb": {
                "lat": 45.8131,
                "lng": 15.9775
            }
        }
    },
    "LT": {
        "name": "Lithuania",
        "capital": {
            "Vilnius": {
                "lat": 54.6872,
                "lng": 25.28
            }
        }
    },
    "MD": {
        "name": "Moldova",
        "capital": {
            "Chisinau": {
                "lat": 47.0228,
                "lng": 28.8353
            }
        }
    },
    "PG": {
        "name": "Papua New Guinea",
        "capital": {
            "Port Moresby": {
                "lat": -9.4789,
                "lng": 147.1494
            }
        }
    },
    "BJ": {
        "name": "Benin",
        "capital": {
            "Cotonou": {
                "lat": 6.3667,
                "lng": 2.4333
            }
        }
    },
    "BH": {
        "name": "Bahrain",
        "capital": {
            "Manama": {
                "lat": 26.225,
                "lng": 50.5775
            }
        }
    },
    "EE": {
        "name": "Estonia",
        "capital": {
            "Tallinn": {
                "lat": 59.4372,
                "lng": 24.7453
            }
        }
    },
    "DJ": {
        "name": "Djibouti",
        "capital": {
            "Djibouti": {
                "lat": 11.5883,
                "lng": 43.145
            }
        }
    },
    "TN": {
        "name": "Tunisia",
        "capital": {
            "Tunis": {
                "lat": 36.8064,
                "lng": 10.1817
            }
        }
    },
    "XG": {
        "name": "Gaza Strip",
        "capital": {
            "Gaza": {
                "lat": 31.5069,
                "lng": 34.456
            }
        }
    },
    "JM": {
        "name": "Jamaica",
        "capital": {
            "Kingston": {
                "lat": 17.9714,
                "lng": -76.7931
            }
        }
    },
    "MO": {
        "name": "Macau",
        "capital": {
            "Macau": {
                "lat": 22.2006,
                "lng": 113.5461
            }
        }
    },
    "MK": {
        "name": "North Macedonia",
        "capital": {
            "Skopje": {
                "lat": 41.9961,
                "lng": 21.4317
            }
        }
    },
    "GW": {
        "name": "Guinea-Bissau",
        "capital": {
            "Bissau": {
                "lat": 11.85,
                "lng": -15.5667
            }
        }
    },
    "MT": {
        "name": "Malta",
        "capital": {
            "Valletta": {
                "lat": 35.8983,
                "lng": 14.5125
            }
        }
    },
    "PY": {
        "name": "Paraguay",
        "capital": {
            "Asunción": {
                "lat": -25.3,
                "lng": -57.6333
            }
        }
    },
    "SK": {
        "name": "Slovakia",
        "capital": {
            "Bratislava": {
                "lat": 48.1439,
                "lng": 17.1097
            }
        }
    },
    "SS": {
        "name": "South Sudan",
        "capital": {
            "Juba": {
                "lat": 4.85,
                "lng": 31.6
            }
        }
    },
    "CH": {
        "name": "Switzerland",
        "capital": {
            "Zürich": {
                "lat": 47.3744,
                "lng": 8.5411
            }
        }
    },
    "NaN": {
        "name": "Namibia",
        "capital": {
            "Windhoek": {
                "lat": -22.57,
                "lng": 17.0836
            }
        }
    },
    "BA": {
        "name": "Bosnia and Herzegovina",
        "capital": {
            "Sarajevo": {
                "lat": 43.8564,
                "lng": 18.4131
            }
        }
    },
    "AL": {
        "name": "Albania",
        "capital": {
            "Tirana": {
                "lat": 41.3289,
                "lng": 19.8178
            }
        }
    },
    "GM": {
        "name": "Gambia, The",
        "capital": {
            "Banjul": {
                "lat": 13.4531,
                "lng": -16.5775
            }
        }
    },
    "LS": {
        "name": "Lesotho",
        "capital": {
            "Maseru": {
                "lat": -29.31,
                "lng": 27.48
            }
        }
    },
    "CY": {
        "name": "Cyprus",
        "capital": {
            "Nicosia": {
                "lat": 35.1725,
                "lng": 33.365
            }
        }
    },
    "SV": {
        "name": "El Salvador",
        "capital": {
            "San Salvador": {
                "lat": 13.6989,
                "lng": -89.1914
            }
        }
    },
    "RE": {
        "name": "Reunion",
        "capital": {
            "Saint-Denis": {
                "lat": -20.8789,
                "lng": 55.4481
            }
        }
    },
    "GQ": {
        "name": "Equatorial Guinea",
        "capital": {
            "Malabo": {
                "lat": 3.7456,
                "lng": 8.7744
            }
        }
    },
    "SI": {
        "name": "Slovenia",
        "capital": {
            "Ljubljana": {
                "lat": 46.0514,
                "lng": 14.5061
            }
        }
    },
    "BS": {
        "name": "Bahamas, The",
        "capital": {
            "Nassau": {
                "lat": 25.0781,
                "lng": -77.3386
            }
        }
    },
    "MQ": {
        "name": "Martinique",
        "capital": {
            "Fort-de-France": {
                "lat": 14.6,
                "lng": -61.0667
            }
        }
    },
    "GP": {
        "name": "Guadeloupe",
        "capital": {
            "Pointe-à-Pitre": {
                "lat": 16.2411,
                "lng": -61.5331
            }
        }
    },
    "BW": {
        "name": "Botswana",
        "capital": {
            "Gaborone": {
                "lat": -24.6581,
                "lng": 25.9122
            }
        }
    },
    "SR": {
        "name": "Suriname",
        "capital": {
            "Paramaribo": {
                "lat": 5.8522,
                "lng": -55.2039
            }
        }
    },
    "TL": {
        "name": "Timor-Leste",
        "capital": {
            "Dili": {
                "lat": -8.5536,
                "lng": 125.5783
            }
        }
    },
    "XK": {
        "name": "Kosovo",
        "capital": {
            "Prizren": {
                "lat": 42.2128,
                "lng": 20.7392
            }
        }
    },
    "XW": {
        "name": "West Bank",
        "capital": {
            "Hebron": {
                "lat": 31.5286,
                "lng": 35.0969
            }
        }
    },
    "GY": {
        "name": "Guyana",
        "capital": {
            "Georgetown": {
                "lat": 6.8058,
                "lng": -58.1508
            }
        }
    },
    "FJ": {
        "name": "Fiji",
        "capital": {
            "Suva": {
                "lat": -18.1416,
                "lng": 178.4419
            }
        }
    },
    "NC": {
        "name": "New Caledonia",
        "capital": {
            "Nouméa": {
                "lat": -22.2758,
                "lng": 166.458
            }
        }
    },
    "ME": {
        "name": "Montenegro",
        "capital": {
            "Podgorica": {
                "lat": 42.4414,
                "lng": 19.2628
            }
        }
    },
    "CW": {
        "name": "Curaçao",
        "capital": {
            "Willemstad": {
                "lat": 12.108,
                "lng": -68.935
            }
        }
    },
    "MU": {
        "name": "Mauritius",
        "capital": {
            "Port Louis": {
                "lat": -20.1644,
                "lng": 57.5042
            }
        }
    },
    "IS": {
        "name": "Iceland",
        "capital": {
            "Reykjavík": {
                "lat": 64.1467,
                "lng": -21.94
            }
        }
    },
    "MV": {
        "name": "Maldives",
        "capital": {
            "Male": {
                "lat": 4.1753,
                "lng": 73.5089
            }
        }
    },
    "LU": {
        "name": "Luxembourg",
        "capital": {
            "Luxembourg": {
                "lat": 49.6117,
                "lng": 6.1319
            }
        }
    },
    "PF": {
        "name": "French Polynesia",
        "capital": {
            "Papeete": {
                "lat": -17.5334,
                "lng": -149.5667
            }
        }
    },
    "GU": {
        "name": "Guam",
        "capital": {
            "Maina": {
                "lat": 13.4692,
                "lng": 144.7332
            }
        }
    },
    "BT": {
        "name": "Bhutan",
        "capital": {
            "Thimphu": {
                "lat": 27.4722,
                "lng": 89.6361
            }
        }
    },
    "TG": {
        "name": "Togo",
        "capital": {
            "Sokodé": {
                "lat": 8.9833,
                "lng": 1.1333
            }
        }
    },
    "SZ": {
        "name": "Eswatini",
        "capital": {
            "Manzini": {
                "lat": -26.4833,
                "lng": 31.3667
            }
        }
    },
    "BB": {
        "name": "Barbados",
        "capital": {
            "Bridgetown": {
                "lat": 13.0975,
                "lng": -59.6167
            }
        }
    },
    "TT": {
        "name": "Trinidad and Tobago",
        "capital": {
            "Chaguanas": {
                "lat": 10.5167,
                "lng": -61.4
            }
        }
    },
    "SB": {
        "name": "Solomon Islands",
        "capital": {
            "Honiara": {
                "lat": -9.4319,
                "lng": 159.9556
            }
        }
    },
    "YT": {
        "name": "Mayotte",
        "capital": {
            "Mamoudzou": {
                "lat": -12.7806,
                "lng": 45.2278
            }
        }
    },
    "CV": {
        "name": "Cabo Verde",
        "capital": {
            "Mindelo": {
                "lat": 16.886,
                "lng": -24.988
            }
        }
    },
    "LC": {
        "name": "Saint Lucia",
        "capital": {
            "Castries": {
                "lat": 14.0167,
                "lng": -60.9833
            }
        }
    },
    "ST": {
        "name": "Sao Tome and Principe",
        "capital": {
            "São Tomé": {
                "lat": 0.3361,
                "lng": 6.7306
            }
        }
    },
    "GF": {
        "name": "French Guiana",
        "capital": {
            "Cayenne": {
                "lat": 4.933,
                "lng": -52.33
            }
        }
    },
    "BZ": {
        "name": "Belize",
        "capital": {
            "Belize City": {
                "lat": 17.4986,
                "lng": -88.1886
            }
        }
    },
    "BN": {
        "name": "Brunei",
        "capital": {
            "Bandar Seri Begawan": {
                "lat": 4.8903,
                "lng": 114.9422
            }
        }
    },
    "VU": {
        "name": "Vanuatu",
        "capital": {
            "Port-Vila": {
                "lat": -17.7333,
                "lng": 168.3167
            }
        }
    },
    "WS": {
        "name": "Samoa",
        "capital": {
            "Apia": {
                "lat": -13.8333,
                "lng": -171.75
            }
        }
    },
    "MC": {
        "name": "Monaco",
        "capital": {
            "Monaco": {
                "lat": 43.7333,
                "lng": 7.4167
            }
        }
    },
    "AW": {
        "name": "Aruba",
        "capital": {
            "Oranjestad": {
                "lat": 12.5186,
                "lng": -70.0358
            }
        }
    },
    "GI": {
        "name": "Gibraltar",
        "capital": {
            "Gibraltar": {
                "lat": 36.14,
                "lng": -5.35
            }
        }
    },
    "JE": {
        "name": "Jersey",
        "capital": {
            "Saint Helier": {
                "lat": 49.1858,
                "lng": -2.11
            }
        }
    },
    "MH": {
        "name": "Marshall Islands",
        "capital": {
            "Majuro": {
                "lat": 7.0833,
                "lng": 171.3833
            }
        }
    },
    "KM": {
        "name": "Comoros",
        "capital": {
            "Mutsamudu": {
                "lat": -12.1675,
                "lng": 44.3939
            }
        }
    },
    "KI": {
        "name": "Kiribati",
        "capital": {
            "Tarawa": {
                "lat": 1.3382,
                "lng": 173.0176
            }
        }
    },
    "IM": {
        "name": "Isle of Man",
        "capital": {
            "Douglas": {
                "lat": 54.15,
                "lng": -4.4775
            }
        }
    },
    "KY": {
        "name": "Cayman Islands",
        "capital": {
            "George Town": {
                "lat": 19.2866,
                "lng": -81.3744
            }
        }
    },
    "SC": {
        "name": "Seychelles",
        "capital": {
            "Victoria": {
                "lat": -4.6231,
                "lng": 55.4525
            }
        }
    },
    "VC": {
        "name": "Saint Vincent and the Grenadines",
        "capital": {
            "Calliaqua": {
                "lat": 13.1308,
                "lng": -61.1928
            }
        }
    },
    "TO": {
        "name": "Tonga",
        "capital": {
            "Nuku‘alofa": {
                "lat": -21.1333,
                "lng": -175.2
            }
        }
    },
    "AD": {
        "name": "Andorra",
        "capital": {
            "Andorra la Vella": {
                "lat": 42.5,
                "lng": 1.5
            }
        }
    },
    "AG": {
        "name": "Antigua and Barbuda",
        "capital": {
            "Saint John’s": {
                "lat": 17.1167,
                "lng": -61.85
            }
        }
    },
    "GG": {
        "name": "Guernsey",
        "capital": {
            "Saint Peter Port": {
                "lat": 49.4555,
                "lng": -2.5368
            }
        }
    },
    "GL": {
        "name": "Greenland",
        "capital": {
            "Nuuk": {
                "lat": 64.1814,
                "lng": -51.6942
            }
        }
    },
    "DM": {
        "name": "Dominica",
        "capital": {
            "Roseau": {
                "lat": 15.3014,
                "lng": -61.3883
            }
        }
    },
    "FM": {
        "name": "Micronesia, Federated States of",
        "capital": {
            "Weno": {
                "lat": 7.4417,
                "lng": 151.8583
            }
        }
    },
    "FO": {
        "name": "Faroe Islands",
        "capital": {
            "Tórshavn": {
                "lat": 62.0,
                "lng": -6.7833
            }
        }
    },
    "KN": {
        "name": "Saint Kitts and Nevis",
        "capital": {
            "Basseterre": {
                "lat": 17.3,
                "lng": -62.7333
            }
        }
    },
    "VG": {
        "name": "Virgin Islands, British",
        "capital": {
            "Road Town": {
                "lat": 18.4167,
                "lng": -64.6167
            }
        }
    },
    "AS": {
        "name": "American Samoa",
        "capital": {
            "Pago Pago": {
                "lat": -14.274,
                "lng": -170.7046
            }
        }
    },
    "GD": {
        "name": "Grenada",
        "capital": {
            "Saint David’s": {
                "lat": 12.0444,
                "lng": -61.6806
            }
        }
    },
    "SM": {
        "name": "San Marino",
        "capital": {
            "Serravalle": {
                "lat": 43.9717,
                "lng": 12.4817
            }
        }
    },
    "BQ": {
        "name": "Bonaire, Sint Eustatius, and Saba",
        "capital": {
            "Kralendijk": {
                "lat": 12.1444,
                "lng": -68.2656
            }
        }
    },
    "PW": {
        "name": "Palau",
        "capital": {
            "Koror": {
                "lat": 7.3419,
                "lng": 134.4792
            }
        }
    },
    "TV": {
        "name": "Tuvalu",
        "capital": {
            "Funafuti": {
                "lat": -8.5167,
                "lng": 179.2
            }
        }
    },
    "LI": {
        "name": "Liechtenstein",
        "capital": {
            "Schaan": {
                "lat": 47.1667,
                "lng": 9.5097
            }
        }
    },
    "MF": {
        "name": "Saint Martin",
        "capital": {
            "Marigot": {
                "lat": 18.0706,
                "lng": -63.0847
            }
        }
    },
    "PM": {
        "name": "Saint Pierre and Miquelon",
        "capital": {
            "Saint-Pierre": {
                "lat": 46.7778,
                "lng": -56.1778
            }
        }
    },
    "CK": {
        "name": "Cook Islands",
        "capital": {
            "Avarua": {
                "lat": -21.207,
                "lng": -159.771
            }
        }
    },
    "TC": {
        "name": "Turks and Caicos Islands",
        "capital": {
            "Grand Turk": {
                "lat": 21.459,
                "lng": -71.139
            }
        }
    },
    "AI": {
        "name": "Anguilla",
        "capital": {
            "The Valley": {
                "lat": 18.2208,
                "lng": -63.0517
            }
        }
    },
    "MP": {
        "name": "Northern Mariana Islands",
        "capital": {
            "Capitol Hill": {
                "lat": 15.2137,
                "lng": 145.7546
            }
        }
    },
    "BL": {
        "name": "Saint Barthelemy",
        "capital": {
            "Gustavia": {
                "lat": 17.8979,
                "lng": -62.8506
            }
        }
    },
    "FK": {
        "name": "Falkland Islands (Islas Malvinas)",
        "capital": {
            "Stanley": {
                "lat": -51.7,
                "lng": -57.85
            }
        }
    },
    "SX": {
        "name": "Sint Maarten",
        "capital": {
            "Philipsburg": {
                "lat": 18.0237,
                "lng": -63.0458
            }
        }
    },
    "XR": {
        "name": "Svalbard",
        "capital": {
            "Longyearbyen": {
                "lat": 78.22,
                "lng": 15.65
            }
        }
    },
    "CX": {
        "name": "Christmas Island",
        "capital": {
            "Flying Fish Cove": {
                "lat": -10.4261,
                "lng": 105.6728
            }
        }
    },
    "WF": {
        "name": "Wallis and Futuna",
        "capital": {
            "Mata-Utu": {
                "lat": -13.2833,
                "lng": -176.1833
            }
        }
    },
    "BM": {
        "name": "Bermuda",
        "capital": {
            "Hamilton": {
                "lat": 32.293,
                "lng": -64.782
            }
        }
    },
    "VA": {
        "name": "Vatican City",
        "capital": {
            "Vatican City": {
                "lat": 41.904,
                "lng": 12.453
            }
        }
    },
    "NR": {
        "name": "Nauru",
        "capital": {
            "Yaren": {
                "lat": -0.5477,
                "lng": 166.9209
            }
        }
    },
    "SH": {
        "name": "Saint Helena, Ascension, and Tristan da Cunha",
        "capital": {
            "Jamestown": {
                "lat": -15.9251,
                "lng": -5.7179
            }
        }
    },
    "NU": {
        "name": "Niue",
        "capital": {
            "Alofi": {
                "lat": -19.056,
                "lng": -169.921
            }
        }
    },
    "MS": {
        "name": "Montserrat",
        "capital": {
            "Brades": {
                "lat": 16.7928,
                "lng": -62.2106
            }
        }
    },
    "NF": {
        "name": "Norfolk Island",
        "capital": {
            "Kingston": {
                "lat": -29.0606,
                "lng": 167.9619
            }
        }
    },
    "GS": {
        "name": "South Georgia and South Sandwich Islands",
        "capital": {
            "Grytviken": {
                "lat": -54.2806,
                "lng": -36.508
            }
        }
    },
    "PN": {
        "name": "Pitcairn Islands",
        "capital": {
            "Adamstown": {
                "lat": -25.0667,
                "lng": -130.1
            }
        }
    },
    "VI": {
        "name": "U.S. Virgin Islands",
        "capital": {
            "Charlotte Amalie": {
                "lat": 18.342,
                "lng": -64.9331
            }
        }
    },
    "PS": {
        "name": "Palestine",
        "capital": {
            "Ramallah": {
                "lat": 31.8996,
                "lng": 35.2042
            }
        }
    }
}