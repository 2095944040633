import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {getUserName, useProfileCredentials} from "@iblai/ibl-web-react-common";
import {NavLink} from "react-router-dom";
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import {BADGES_TOOLTIPS, ORDERED_CRED_LISTS} from "../../hooks/utils/helpers";
interface AchievementsProps{
    displayCertificates:boolean,
    setEnabled:Dispatch<SetStateAction<boolean>>,
}

const Achievements : React.FC<AchievementsProps> = ({displayCertificates, setEnabled}) => {

    function filterUniqueByCredentialEntityId(data:any[]) {
        const seen = new Set();
        return data.filter(item => {
            const credentialId = item.credentialDetails.entityId;
            if (!seen.has(credentialId)) {
                seen.add(credentialId);
                return true;
            }
            return false;
        });
    }

    const {
        userCredentials,
        userCredentialsLoaded,
        discoverCredentials,
        discoverCredentialsLoaded,
    } = useProfileCredentials(getUserName());

    const [filteredCredentials, setFilteredCredentials] = useState<any[]>([])

    useEffect(() => {
        if(userCredentialsLoaded){
            const _filteredCredentials:any[] = displayCertificates ? userCredentials.filter((pre)=>Array.isArray(pre?.credentialDetails?.courses) && pre?.credentialDetails?.courses?.length > 0)  :
                userCredentials.filter((pre)=>Array.isArray(pre?.credentialDetails?.courses) && pre?.credentialDetails?.courses?.length === 0)
            //DUPLICATES TO BE REMOVED
            const _filteredCredWithoutDuplicates = filterUniqueByCredentialEntityId(_filteredCredentials)
            if(_filteredCredWithoutDuplicates.length === 0){
                setEnabled(false)
            }
            setFilteredCredentials(displayCertificates ? ORDERED_CRED_LISTS.map((credName:string)=>(_filteredCredWithoutDuplicates.find((pre)=>pre?.credentialDetails?.name === credName))).filter((cred)=>!!cred) : _filteredCredWithoutDuplicates)
        }

    }, [userCredentialsLoaded]);


    return (
        <>


            {
                filteredCredentials.map((credential, index) => {
                    return displayCertificates ? (
                        <React.Fragment key={`achievement-cred-${index}`}>
                            <CertificateDisplay credential={credential} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={`achievement-cred-${index}`}>
                            <BadgeDisplay credential={credential} />
                        </React.Fragment>
                    )
                })
            }


        </>
    )
}

interface CertificateDisplayProps {
    credential:any,
}

const CertificateDisplay : React.FC<CertificateDisplayProps> = ({credential}) => {
    const credentialImageURL= (credential: any) => {
        return (
            credential?.credentialDetails?.iconImage || '/img/default_badge.png'
        );
    }
    return (
        <NavLink
            className="cred-box"
            to={`/certificate/${credential?.entityId}`}
            target="_blank"
        >
            {" "}
            <img
                data-name="badge_image"
                loading="lazy"
                alt=""
                src={credentialImageURL(credential)}
                onError={(e) => (e.currentTarget.src = '/img/default_badge.png')}
                className="image-16"
            />
            <div>
                {credential?.credentialDetails?.name}
                <div></div>
            </div>
        </NavLink>
    )
}

const BadgeDisplay : React.FC<CertificateDisplayProps> = ({credential}) => {
    const credentialImageURL= (credential: any) => {
        return (
            credential?.credentialDetails?.iconImage || '/img/default_badge.png'
        );
    }

    const [tooltip, setTooltip] = useState("")

    useEffect(() => {
        setTooltip(BADGES_TOOLTIPS.find((pre)=>pre.label === credential?.credentialDetails?.name)?.description ?? "")
    }, [credential]);

    return (
        <SlTooltip>
            <div style={{textAlign:"center"}} slot="content">
                <span>{tooltip}</span>
            </div>
            <a
                className="cred-box"
                href={"#"}
                onClick={(e) => e.preventDefault()}
            >
                {" "}
                <img
                    data-name="badge_image"
                    loading="lazy"
                    alt=""
                    src={credentialImageURL(credential)}
                    onError={(e) => (e.currentTarget.src = '/img/default_badge.png')}
                    className="image-16"
                />
                <div>
                    {credential?.credentialDetails?.name}
                    <div></div>
                </div>
            </a>
        </SlTooltip>
    )
}

export default Achievements