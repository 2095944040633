import React, {useContext, useState} from "react";
import useHeaderFooter from "../../hooks/useHeaderFooter";
import './ProfileLearner.css'
import {AppDataContext} from "@iblai/ibl-web-react-common";
import {NavLink} from "react-router-dom";
import UserEditPopUp from "../../components/UserEditPopUp/UserEditPopUp";
import Achievements from "../../components/Achievements/Achievements";
import CoursesInProgress from "../../components/CoursesInProgress/CoursesInProgress";

interface ProfileLearnerProps {
    children?: React.ReactNode
}

const ProfileLearner : React.FC<ProfileLearnerProps> = ({children}) => {
    const { headerContent, footerContent } = useHeaderFooter(children);
    const {appData, setAppData} = useContext(AppDataContext);
    const [isProfileModalOpen, setProfileModalOpen] = useState(false);
    const [badgesEnabled, setBadgesEnabled] = useState(true)


    return (
        <>
            <input
                type="hidden"
                id="anPageName"
                name="page"
                defaultValue="profile-learner"
            />
            <div className="container-center-horizontal">
                <div className="profile-learner screen">
                    <div className="overlap-group8">
                        <div className="hero-image">
                            <div className="overlap-group">
                                <h1 className="learner-saanvi-khatri recklessneue-book-normal-white-100-1px">
                                    Learner
                                    <br />
                                    <span className="c-name">{appData.userData.user_fullname}</span>
                                </h1>
                                <img className="layer_4" src="/img/layer-4.svg" alt="Layer_4" />
                            </div>
                        </div>
                        {headerContent}
                    </div>
                    <div className="page-nav page">
                        <div className="dashboard-your-profile recklessneue-book-normal-congress-blue-22-8px">
                            <NavLink
                                id="dashboard-link"
                                to={`/user-dashboard/${appData.userMetaData.username}`}
                            >
                                {" "}
                                Dashboard{" "}
                            </NavLink>{" "}
                            &gt; Your Profile
                        </div>
                        <img className="line-6 line" src="/img/line-6-5.svg" alt="Line 6" />
                    </div>
                    <div className="mentor-bio">
                        <div className="flex-row flex learner-bio-block">
                            <div
                                className="headshot public-profile-image-container"
                                style={{ visibility: "visible" , cursor: "default"}}
                            >
                                <img
                                    className="image-9 user-img"
                                    src={`${appData?.userMetaData?.profile_image?.image_url_full}`}

                                    alt="image 9"
                                />
                            </div>
                            {/*<input*/}
                            {/*    type="file"*/}
                            {/*    id="profileImageInput"*/}
                            {/*    name="imageInput"*/}
                            {/*    accept="image/*"*/}
                            {/*/>*/}
                            <div className="overlap-group7">
                                <div className="overlap-group-1">
                                    <p className="lorem-ipsum-dolor-si-1 neuehaasgroteskdisplaypro-45-light-congress-blue-24px c-bio">
                                        {appData?.userMetaData?.bio}
                                    </p>
                                    <div className="learner-bio instrumentserif-regular-normal-congress-blue-48px">
                <span className="instrumentserif-normal-congress-blue-48px">
                  Learner{" "}
                </span>
                                        <span className="recklessneue-regular-normal-congress-blue-48px">
                  Bio
                </span>
                                    </div>
                                </div>
                                <div className="socials">
                                    <a data-name="email" href={`mailto:${appData.userMetaData.email}`}>
                                        {" "}
                                        <img className="email" src="/img/email.svg" alt="Email" />{" "}
                                    </a>

                                    {appData?.userMetaData?.social_links?.find(link => link?.platform === "twitter")?.social_link && (
                                        <a data-name="twitter" href={appData?.userMetaData?.social_links?.find(link => link?.platform === "twitter")?.social_link}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            {" "}
                                            <img
                                                className="twitter"
                                                src="/img/twitter.svg"
                                                alt="Twitter"
                                            />{" "}
                                        </a>
                                    )}

                                    {appData?.userMetaData?.social_links?.find(link => link?.platform === "linkedin")?.social_link && (
                                        <a
                                            data-name="linkedin"
                                            href={appData?.userMetaData?.social_links?.find(link => link?.platform === "linkedin")?.social_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {" "}
                                            <img
                                                className="linkedin"
                                                src="/img/linkedin.svg"
                                                alt="Linkedin"
                                            />{" "}
                                        </a>
                                    )}

                                    {appData.userMetaDataLoaded && (<img
                                        className="learner-profile-edit edit"
                                        src="/img/edit2.svg"
                                        onClick={() => setProfileModalOpen(true)}
                                        alt="Edit"
                                        style={{display: "inline"}}
                                    />)}

                                    {/* <img class="youtube" src="img/youtube.svg" alt="Youtube" /> */}
                                </div>
                            </div>
                        </div>
                        {/* <div class="button"><div class="connect instrumentsans-normal-white-26px">Connect</div></div> */}
                    </div>
                    {
                        badgesEnabled && (
                            <div className="badges">
                                <div className="badges-container">
                                    <div className="has-earnt-these-badges recklessneue-book-normal-congress-blue-48px">
                                        Has earnt
                                        <br/>
                                        These badges
                                    </div>

                                    <div className="badges-1">
                                        <Achievements displayCertificates={false} setEnabled={setBadgesEnabled}/>

                                    </div>

                                </div>
                            </div>
                        )
                    }
                    <CoursesInProgress/>
                    {footerContent}
                    {isProfileModalOpen && (
                        <UserEditPopUp appData={appData} setProfileModalOpen={setProfileModalOpen}
                                       setAppData={setAppData}/>
                    )}
                </div>
            </div>

        </>

    );
}

export default ProfileLearner;