import { api, getUserId } from "@iblai/ibl-web-react-common";
import axios from "axios";
import { CourseCatalogueItem } from "../useCourseCatalogue";

export const sample = () => {};

interface SearchCourseData {
  _index: string;
  _id: string;
  _score: number;
  _ignored?: string[];
  _source: {
    id: number;
    course_id: string;
    name: string;
    slug: string | null;
    platform_id: number;
    data: string;
    item_id: number;
  };
}

interface CompletionData {
  total_units: number;
  total_blocks: number;
  total_sections: number;
  completed_units: number;
  completed_blocks: number;
  total_subsections: number;
  completed_sections: number;
  completed_subsections: number;
}

interface CourseData {
  course_id: string;
  org: string;
  user_id: number;
  username: string;
  completion_percentage: number;
  completed: boolean;
  last_updated: string; // ISO date string
  completion_date: string | null; // null when not completed
  completion_data: CompletionData;
  grading_percentage: number;
  passed: boolean;
  passing_date: string | null; // null when not passed
  grade_data: Record<string, any>; // Object to handle dynamic grade data
}
export interface userCourseCompletion {
  [courseId: string]: CourseData;
}

export function addArrowClickHandlers(): void {
  const coursesGrid = document.querySelector(
    ".course-container"
  ) as HTMLElement;
  let courseWidth: number;
  let currentPosition = 0;
  let totalItems: number;
  let rectangle: HTMLElement;
  let initialWidth: number;

  function calculateWidths(): void {
    const courseItem = document.querySelector(
      ".course-item-custom"
    ) as HTMLElement;
    courseWidth = (courseItem?.offsetWidth ?? 0) + 24; // added the gap width
    totalItems = coursesGrid.children.length;
    rectangle = document.querySelector(
      ".courses-slider-control .overlap-group-2 .rectangle-7"
    ) as HTMLElement;
    initialWidth = 100 / totalItems;
    rectangle.style.width = `${initialWidth}%`;
  }

  function updateRectangleWidth(): void {
    const currentPositionPercentage =
      (-currentPosition / (courseWidth * (totalItems - 1))) * 100;
    let newWidth =
      (initialWidth + currentPositionPercentage) * (100 / (100 + initialWidth));
    newWidth = Math.min(newWidth, 100);
    rectangle.style.width = `${newWidth.toFixed(2)}%`;
  }

  function updateButtonStates(): void {
    const previousButton = document.querySelector(
      ".previous_course"
    ) as HTMLButtonElement;
    const nextButton = document.querySelector(
      ".next_course"
    ) as HTMLButtonElement;

    if (currentPosition === 0) {
      previousButton.classList.add("disabled");
      previousButton.disabled = true;
    } else {
      previousButton.classList.remove("disabled");
      previousButton.disabled = false;
    }

    if (currentPosition <= -courseWidth * (totalItems - 1)) {
      nextButton.classList.add("disabled");
      nextButton.disabled = true;
    } else {
      nextButton.classList.remove("disabled");
      nextButton.disabled = false;
    }
  }

  calculateWidths();
  updateButtonStates();
  updateRectangleWidth();

  const nextButton = document.querySelector(
    ".next_course"
  ) as HTMLButtonElement;
  nextButton.addEventListener("click", () => {
    currentPosition -= courseWidth;
    coursesGrid.style.transform = `translateX(${currentPosition}px)`;
    updateButtonStates();
    updateRectangleWidth();
  });

  const previousButton = document.querySelector(
    ".previous_course"
  ) as HTMLButtonElement;
  previousButton.addEventListener("click", () => {
    currentPosition += courseWidth;
    coursesGrid.style.transform = `translateX(${currentPosition}px)`;
    updateButtonStates();
    updateRectangleWidth();
  });

  window.addEventListener("resize", () => {
    calculateWidths(); // Recalculate widths on window resize
    updateButtonStates();
    updateRectangleWidth();
  });
}

export const BannerImageMapping = {
  "course-v1:Legatum+104+2023": "/img/LOVE-MODULE-LWC.jpg",
  "course-v1:Legatum+4+2023": "/img/LOVE-MODULE-LWC.jpg",
  "course-v1:Legatum+106+2023": "/img/HOPE-MODULE.jpeg",
  "course-v1:Legatum+5+2023": "/img/HOPE-MODULE.jpeg",
  "course-v1:Legatum+103+2023": "/img/COURAGE-MODULE-LWC.jpg",
  "course-v1:Legatum+3+2023": "/img/COURAGE-MODULE-LWC.jpg",
  "course-v1:Legatum+101+2023": "/img/WHYLEAD-MODULE-LWC.jpg",
  "course-v1:Legatum+102+2023": "/img/WHYLEAD-MODULE-LWC.jpg",
  "course-v1:Legatum+105+2023": "/img/CHANGE-MODULE-LWC.jpg",
  "course-v1:Legatum+6+2023": "/img/CHANGE-MODULE-LWC.jpg",
  "course-v1:Legatum+100+2023": "/img/GATEWAY-Header.jpg",
  "course-v1:Legatum+107+2023": "/img/leading-forward-banner.jpg",
};

interface CourseProgress {
  can_show_upgrade_sock: boolean;
  verified_mode: string | null;
  access_expiration: string | null;
  certificate_data: {
    cert_status: string;
    cert_web_view_url: string | null;
    download_url: string | null;
    certificate_available_date: string | null;
  };
  completion_summary: {
    complete_count: number;
    incomplete_count: number;
    locked_count: number;
  };
  course_grade: {
    letter_grade: string;
    percent: number;
    is_passing: boolean;
  };
  credit_course_requirements: string | null;
  end: string | null;
  enrollment_mode: string;
  grading_policy: {
    assignment_policies: {
      num_droppable: number;
      num_total: number;
      short_label: string;
      type: string;
      weight: number;
    }[];
    grade_range: {
      [key: string]: number;
    };
  };
  has_scheduled_content: boolean | null;
  section_scores: {
    display_name: string;
    subsections: {
      assignment_type: string;
      block_key: string;
      display_name: string;
      has_graded_assignment: boolean;
      override: string | null;
      learner_has_access: boolean;
      num_points_earned: number;
      num_points_possible: number;
      percent_graded: number;
      problem_scores: {
        earned: number;
        possible: number;
      }[];
      show_correctness: string;
      show_grades: boolean;
      url: string;
    }[];
  }[];
  studio_url: string;
  username: string;
  user_has_passing_grade: boolean;
  verification_data: {
    link: string | null;
    status: string;
    status_date: string | null;
  };
}

export const isEligibleToEnroll = (
  prerequisite: string | undefined,
  setLoaded: React.Dispatch<React.SetStateAction<boolean>> | null
): boolean => {
  setLoaded && setLoaded(false);

  if (!prerequisite) {
    setLoaded && setLoaded(true);
    return false;
  }

  api.ibledxcourses.getCourseProgress(
    { course_id: prerequisite },
    (data: CourseProgress) => {
      setLoaded && setLoaded(true);

      try {
        const { complete_count, incomplete_count } = data.completion_summary;
        const progressPercentage =
          (complete_count / (complete_count + incomplete_count)) * 100;
        return progressPercentage === 100;
      } catch (e) {
        return false;
      }
    },
    () => {
      setLoaded && setLoaded(true);
      return false;
    }
  );

  return false;
};

export const isEligibleToEnrollV2 = (
  prerequisite: string | undefined,
  setLoaded: React.Dispatch<React.SetStateAction<boolean>> | null,
  returnCompletion?: boolean
): Promise<boolean | { eligible: boolean; completed: boolean }> => {
  return new Promise((resolve) => {
    setLoaded && setLoaded(false);

    if (!prerequisite) {
      // if not prerequisite set, I guess we should resolve to t
      setLoaded && setLoaded(true);
      resolve(returnCompletion ? { eligible: true, completed: false } : true);
      return;
    }

    if (isLoggedIn()) {
      api.ibledxcourses.getCourseProgress(
        { course_id: prerequisite },
        (data: CourseProgress) => {
          setLoaded && setLoaded(true);

          try {
            const { complete_count, incomplete_count } =
              data.completion_summary;
            const progressPercentage =
              Math.floor(complete_count / (complete_count + incomplete_count)) *
              100;
            if (returnCompletion) {
              resolve(
                progressPercentage === 100
                  ? { eligible: true, completed: true }
                  : { eligible: false, completed: false }
              );
            } else {
              resolve(progressPercentage === 100);
            }
          } catch (e) {
            resolve(
              returnCompletion ? { eligible: false, completed: false } : false
            );
          }
        },
        () => {
          setLoaded && setLoaded(true);
          resolve(
            returnCompletion ? { eligible: false, completed: false } : false
          );
        }
      );
    } else {
      resolve(returnCompletion ? { eligible: false, completed: false } : false);
    }
  });
};

const searchData =
  '?filter={"_index":"ibl-dm.public.dl_catalog_app_course"}&page_size=' +
  20 +
  "&page=" +
  1;

export interface searchCourseData {
  _index: string;
  _id: string;
  _score: number;
  _ignored?: string[];
  _source: {
    course_id: string;
    name: string;
    slug: string | null;
    id: number;
    platform_id: number;
    item_id: number;
    data: string; // Since 'data' is a string in your requirement
    edx_data: string; // Assuming 'edx_data' is also a string based on your description
    grading_policy: string;
    structure: string;
  };
}

export interface searchDataReturn {
  results: searchCourseData[];
}

export function orgSearch(data: any, callback: any): any {
  const url = `${process.env.REACT_APP_IBL_SEARCH_URL}/api/search/search/${data}`;
  axios
    .get(url)
    .then((response) => {
      // Handle successful response
      callback(response.data);
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
}

export function getCourseCatalogue(): Promise<searchDataReturn> {
  return new Promise<searchDataReturn>((resolve, reject) => {
    orgSearch(searchData, function (results: searchDataReturn) {
      resolve(results);
    });
  });
}

export function getUserCoursesMetaData(course_id: string): any {
  return new Promise((resolve) => {
    api.ibledxcourses.getCourseMeta(
      { course_key: course_id },
      function (data: any) {
        resolve(data);
      }
    );
  });
}

export const isLoggedIn = () => {
  const axdToken = localStorage.getItem("axd_token");
  const dmToken = localStorage.getItem("dm_token");
  return axdToken && dmToken;
};

export const enabledCourseArray = [
  {
    course_id: "course-v1:Legatum+100+2023",
    description:
      "Explore the importance of character in leadership. Learn how to develop your character, setting the direction for your 30-day leadership journey.",
    duration: "1 Hour",
  },
  {
    course_id: "course-v1:Legatum+101+2023",
    description:
      "Develop a strong sense of purpose. Purpose directs our leadership to where our strengths, abilities, and gifts can have a positive impact.",
    duration: "2 hours",
  },
  {
    course_id: "course-v1:Legatum+102+2023",
    description:
      "Purpose directs our leadership to where our strengths, abilities, and gifts can have a positive impact. During this 5-day challenge, you will participate in a series of exercises that must be completed within five days. These exercises will empower you to express your purpose clearly, identify opportunities for promoting positive change, and concentrate on making a meaningful contribution, ultimately leading you to act.",
    duration: "1 hour",
    type: "5-day-challenge",
  },
  {
    course_id: "course-v1:Legatum+103+2023",
    description:
      "Practise courageous action. The kind of leadership required to change things for the better requires courage and self-leadership to overcome external obstacles and internal fears.",
    duration: "2 hours",
  },
  {
    course_id: "course-v1:Legatum+3+2023",
    description:
      "Leading with purpose to build a better world, requires courage and self-leadership to overcome external obstacles and internal fears. Courage enables us to do what is right and good despite adversity, obstacles, and resistance. During this 5-day challenge, you will participate in a series of exercises that you must complete within five days. These exercises will guide you through a series of reflection questions, culminating in the development of a personal action plan to practise leading courageously.",
    duration: "1 hour",
    type: "5-day-challenge",
  },
  {
    course_id: "course-v1:Legatum+104+2023",
    description:
      "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
    duration: "2 hours",
  },
  {
    course_id: "course-v1:Legatum+4+2023",
    description:
      "In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of generative love. This form of love displays care and compassion, fosters growth, and advances the flourishing of self, others, and the broader community. During this challenge, you will participate in a series of exercises that you must complete within five days. These exercises will empower you to practise leadership habits and lead with greater love.",
    duration: "1 hour",
    type: "5-day-challenge",
  },
  {
    course_id: "course-v1:Legatum+106+2023",
    description:
      "Cultivate the hope needed to drive change. Bringing about change is hard and requires hope, focusing collective attention and effort on the possibility of a better future.",
    duration: "2 hours",
  },
  {
    course_id: "course-v1:Legatum+6+2023",
    description:
      "Bringing about change is hard and often requires perseverance over the long term. This requires hope, which is much more than optimism or wishful thinking. Hope is a sustained focus on the future, which enables current action in light of future possibilities. Leaders not only hope for themselves, but also convey hope to others, focusing collective attention and effort on the possibility of a better future.",
    duration: "1 hour",
    type: "5-day-challenge",
  },
  {
    course_id: "course-v1:Legatum+107+2023",
    description:
      "Embark on a lifelong leadership journey. The journey of becoming a better leader is ongoing and lifelong. Craft a plan to integrate character development into your daily life.",
    duration: "1 hour",
  },
];

const userCourseArrayData = {
  tadeyinka: [
    {
      course_id: "course-v1:Legatum+103+2023",
      description:
        "Practise courageous action. The kind of leadership required to change things for the better requires courage and self-leadership to overcome external obstacles and internal fears.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+106+2023",
      description:
        "Cultivate the hope needed to drive change. Bringing about change is hard and requires hope, focusing collective attention and effort on the possibility of a better future.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+107+2023",
      description:
        "Embark on a lifelong leadership journey. The journey of becoming a better leader is ongoing and lifelong. Craft a plan to integrate character development into your daily life.",
      duration: "1 hour",
    },
  ],
  temmie: [
    {
      course_id: "course-v1:Legatum+106+2023",
      description:
        "Cultivate the hope needed to drive change. Bringing about change is hard and requires hope, focusing collective attention and effort on the possibility of a better future.",
      duration: "2 hours",
    },
  ],
  tedward: [
    {
      course_id: "course-v1:Legatum+100+2023",
      description:
        "Explore the importance of character in leadership. Learn how to develop your character, setting the direction for your 30-day leadership journey.",
      duration: "1 Hour",
    },
  ],
  tkaty: [
    {
      course_id: "course-v1:Legatum+104+2023",
      description:
        "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
      duration: "2 hours",
    },
  ],
  tgerald: [
    {
      course_id: "course-v1:Legatum+106+2023",
      description:
        "Cultivate the hope needed to drive change. Bringing about change is hard and requires hope, focusing collective attention and effort on the possibility of a better future.",
      duration: "2 hours",
    },
  ],
  tveronica: [
    {
      course_id: "course-v1:Legatum+103+2023",
      description:
        "Practise courageous action. The kind of leadership required to change things for the better requires courage and self-leadership to overcome external obstacles and internal fears.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+104+2023",
      description:
        "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
      duration: "2 hours",
    },
  ],
  tjoann: [
    {
      course_id: "course-v1:Legatum+103+2023",
      description:
        "Practise courageous action. The kind of leadership required to change things for the better requires courage and self-leadership to overcome external obstacles and internal fears.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+104+2023",
      description:
        "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+107+2023",
      description:
        "Embark on a lifelong leadership journey. The journey of becoming a better leader is ongoing and lifelong. Craft a plan to integrate character development into your daily life.",
      duration: "1 hour",
    },
  ],
  tlina: [
    {
      course_id: "course-v1:Legatum+101+2023",
      description:
        "Develop a strong sense of purpose. Purpose directs our leadership to where our strengths, abilities, and gifts can have a positive impact.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+106+2023",
      description:
        "Cultivate the hope needed to drive change. Bringing about change is hard and requires hope, focusing collective attention and effort on the possibility of a better future.",
      duration: "2 hours",
    },
  ],
  tmatthew: [
    {
      course_id: "course-v1:Legatum+104+2023",
      description:
        "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
      duration: "2 hours",
    },
  ],
  tanjali: [
    {
      course_id: "course-v1:Legatum+100+2023",
      description:
        "Explore the importance of character in leadership. Learn how to develop your character, setting the direction for your 30-day leadership journey.",
      duration: "1 Hour",
    },
    {
      course_id: "course-v1:Legatum+101+2023",
      description:
        "Develop a strong sense of purpose. Purpose directs our leadership to where our strengths, abilities, and gifts can have a positive impact.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+103+2023",
      description:
        "Practise courageous action. The kind of leadership required to change things for the better requires courage and self-leadership to overcome external obstacles and internal fears.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+104+2023",
      description:
        "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+106+2023",
      description:
        "Cultivate the hope needed to drive change. Bringing about change is hard and requires hope, focusing collective attention and effort on the possibility of a better future.",
      duration: "2 hours",
    },
  ],
  tluna: [
    {
      course_id: "course-v1:Legatum+100+2023",
      description:
        "Explore the importance of character in leadership. Learn how to develop your character, setting the direction for your 30-day leadership journey.",
      duration: "1 Hour",
    },
    {
      course_id: "course-v1:Legatum+104+2023",
      description:
        "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+107+2023",
      description:
        "Embark on a lifelong leadership journey. The journey of becoming a better leader is ongoing and lifelong. Craft a plan to integrate character development into your daily life.",
      duration: "1 hour",
    },
  ],
};

export const getEnabledCourse = (
  coursesData: CourseCatalogueItem[],
  username?: string
) => {
  let filteredCourses = coursesData;
  if (username) {
    const userCourses =
      userCourseArrayData[username as keyof typeof userCourseArrayData];
    if (userCourses) {
      filteredCourses = coursesData.filter((courseInstance) =>
        userCourses.some(
          (userCourse) =>
            userCourse.course_id === courseInstance?.search?._source?.course_id
        )
      );
    }
  } else {
    // Filter coursesData based on enabledCourseArray
    filteredCourses = coursesData.filter((courseInstance) =>
      enabledCourseArray.some(
        (enabledCourse) =>
          enabledCourse.course_id ===
            courseInstance?.search?._source?.course_id && !enabledCourse?.type
      )
    );
  }

  return filteredCourses.sort((a, b) => {
    const aIndex = enabledCourseArray.findIndex(
      (enabledCourse) =>
        enabledCourse.course_id === a?.search?._source?.course_id
    );
    const bIndex = enabledCourseArray.findIndex(
      (enabledCourse) =>
        enabledCourse.course_id === b?.search?._source?.course_id
    );
    return aIndex - bIndex;
  });
};

// Function to get the next course ID
export function getNextCourseId(currentCourseId: string): string {
  const courseArray = [
    {
      course_id: "course-v1:Legatum+100+2023",
      description:
        "Explore the importance of character in leadership. Learn how to develop your character, setting the direction for your 30-day leadership journey.",
      duration: "1 Hour",
    },
    {
      course_id: "course-v1:Legatum+101+2023",
      description:
        "Develop a strong sense of purpose. Purpose directs our leadership to where our strengths, abilities, and gifts can have a positive impact.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+102+2023",
      description:
        "Purpose directs our leadership to where our strengths, abilities, and gifts can have a positive impact. During this 5-day challenge, you will participate in a series of exercises that must be completed within five days. These exercises will empower you to express your purpose clearly, identify opportunities for promoting positive change, and concentrate on making a meaningful contribution, ultimately leading you to act.",
      duration: "1 hour",
    },
    {
      course_id: "course-v1:Legatum+103+2023",
      description:
        "Practise courageous action. The kind of leadership required to change things for the better requires courage and self-leadership to overcome external obstacles and internal fears.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+3+2023",
      description:
        "Leading with purpose to build a better world, requires courage and self-leadership to overcome external obstacles and internal fears. Courage enables us to do what is right and good despite adversity, obstacles, and resistance. During this 5-day challenge, you will participate in a series of exercises that you must complete within five days. These exercises will guide you through a series of reflection questions, culminating in the development of a personal action plan to practise leading courageously.",
      duration: "1 hour",
    },
    {
      course_id: "course-v1:Legatum+104+2023",
      description:
        "Build teams and communities that flourish. In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of love.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+4+2023",
      description:
        "In today’s interdependent world, we need leadership built on strong relationships and the foundational virtue of generative love. This form of love displays care and compassion, fosters growth, and advances the flourishing of self, others, and the broader community. During this challenge, you will participate in a series of exercises that you must complete within five days. These exercises will empower you to practise leadership habits and lead with greater love.",
      duration: "1 hour",
    },
    {
      course_id: "course-v1:Legatum+106+2023",
      description:
        "Cultivate the hope needed to drive change. Bringing about change is hard and requires hope, focusing collective attention and effort on the possibility of a better future.",
      duration: "2 hours",
    },
    {
      course_id: "course-v1:Legatum+6+2023",
      description:
        "Bringing about change is hard and often requires perseverance over the long term. This requires hope, which is much more than optimism or wishful thinking. Hope is a sustained focus on the future, which enables current action in light of future possibilities. Leaders not only hope for themselves, but also convey hope to others, focusing collective attention and effort on the possibility of a better future.",
      duration: "1 hour",
    },
    {
      course_id: "course-v1:Legatum+107+2023",
      description:
        "Embark on a lifelong leadership journey. The journey of becoming a better leader is ongoing and lifelong. Craft a plan to integrate character development into your daily life.",
      duration: "1 hour",
    },
  ];
  const currentIndex = courseArray.findIndex(
    (course) => course.course_id === currentCourseId
  );

  // If the course is found and it's not the last one, return the next course
  if (currentIndex !== -1 && currentIndex < courseArray.length - 1) {
    return courseArray[currentIndex + 1].course_id;
  }

  // If the current course is the last one or not found, return the first course
  return courseArray[0].course_id;
}

export const isCourseA5DayChallenge = (
  courseID: string,
  returnIndex = false
) => {
  const conditionLabel = "5-day-challenge";
  const resultIndex = enabledCourseArray.findIndex(
    (pre) => pre?.type === conditionLabel && pre.course_id === courseID
  );
  return returnIndex ? resultIndex : resultIndex !== -1;
};

const getPreviousNon5DayChallengeCourseID = (courseID: string) => {
  const courseIndex = isCourseA5DayChallenge(courseID, true);
  if (typeof courseIndex === "number" && courseIndex > 0) {
    return enabledCourseArray[courseIndex - 1].course_id;
  }
  return null;
};

export const getNextCourseArrays = (
  course_id: string,
  enabledCourseArray: CourseCatalogueItem[]
): CourseCatalogueItem[] => {
  const courseID = !isCourseA5DayChallenge(course_id)
    ? course_id
    : getPreviousNon5DayChallengeCourseID(course_id);
  const index = enabledCourseArray.findIndex(
    (course) => course.search?._source?.course_id === courseID
  );
  if (index === -1 || index === enabledCourseArray.length - 1) {
    return [];
  }
  return enabledCourseArray.slice(index + 1);
};

export const saveUserMetaData = (
  metaData: any,
  successCb: (response: any) => void,
  errorCB: (error: any) => void = () => {}
) => {
  api.ibledxusers.postUsersManageMetadata(
    JSON.stringify(metaData),
    (response: any) => {
      if (successCb) {
        successCb(response);
      }
    },
    (error: any) => {
      if (errorCB) {
        errorCB(error);
      }
    }
  );
};

export const setBirthDate = async (selectedFile: File) => {
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const apiUrl = `${process.env.REACT_APP_IBL_LMS_URL}/api/user/v1/accounts/${userData.user_nicename}`;
  const DEFAULT_YEAR_OF_BIRTH = "1996";

  const payload = {
    year_of_birth: DEFAULT_YEAR_OF_BIRTH,
  };

  await fetch(apiUrl, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });

  localStorage.setItem("birth_date_set", "true");
  await uploadImage(selectedFile);
};

export const uploadImage = async (selectedFile: File) => {
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const maxSize = 1 * 1024 * 1024; // 1MB

  if (selectedFile.size > maxSize) {
    alert("File size exceeds 1 MB. Please select a smaller file.");
    return;
  }

  const formData = new FormData();
  formData.append("file", selectedFile);

  const requestOptions: RequestInit = {
    method: "POST",
    body: formData,
    redirect: "follow",
    credentials: "include",
  };

  // @ts-ignore
  await fetch(
    `${process.env.REACT_APP_IBL_LMS_URL}/api/user/v1/accounts/${userData.user_nicename}/image`,
    requestOptions
  );
};

export const enrollUserToCourse = (
  courseID: string,
  callback: (data: any) => void
) => {
  let data = {
    course_id: courseID,
  };
  api.ibledxcourses.enrollToCourse(data, function (data: any) {
    callback(data);
  });
};

export function addInstructorsGridControl() {
  const tutorContainer = document.querySelector(".tutors-full") as HTMLElement;
  const tutorsGrid = document.querySelector(".tutors-grid") as HTMLElement;
  const tutorElements = tutorsGrid?.querySelectorAll(
    ".tutors-grid > div"
  ) as NodeListOf<HTMLElement>;
  const rectangle = document.querySelector(
    ".course-overview .courses-slider-control .rectangle"
  ) as HTMLElement;
  const prevButton = document.querySelector(
    ".previous_tutor"
  ) as HTMLButtonElement;
  const nextButton = document.querySelector(".next_tutor") as HTMLButtonElement;

  if (
    !tutorContainer ||
    !tutorsGrid ||
    !tutorElements.length ||
    !rectangle ||
    !prevButton ||
    !nextButton
  ) {
    return; // Ensure all elements are available before proceeding
  }

  const tutorWidth = tutorElements[0]?.offsetWidth; // Get the width of a single tutor div including margin
  let visibleTutors = 3; // Number of tutors to show at a time for larger screens
  let currentIndex = 0; // Store the current index of the first visible tutor

  const totalItems = tutorElements.length;
  let totalSteps = Math.max(0, totalItems - visibleTutors);

  function updateTutorsVisibility() {
    if (window.innerWidth <= 988) {
      tutorElements.forEach((tutor) => {
        tutor.style.display = "block";
      });
      tutorsGrid.style.overflowX = "auto";
      tutorsGrid.style.scrollSnapType = "x mandatory";
      tutorElements.forEach((tutor) => {
        tutor.style.scrollSnapAlign = "start";
        tutor.style.flex = "0 0 50%"; // Show two tutors at a time on smaller screens
      });
    } else {
      tutorsGrid.style.overflowX = "hidden";
      tutorsGrid.style.scrollSnapType = "none";
      tutorElements.forEach((tutor, index) => {
        tutor.style.scrollSnapAlign = "none";
        tutor.style.flex = "none";
        if (index >= currentIndex && index < currentIndex + visibleTutors) {
          tutor.style.display = "block";
        } else {
          tutor.style.display = "none";
        }
      });
    }
  }

  function updateRectangleWidth() {
    if (window.innerWidth <= 988) {
      const scrollPercentage =
        tutorsGrid.scrollLeft /
        (tutorsGrid.scrollWidth - tutorsGrid.clientWidth);
      rectangle.style.width = `${scrollPercentage * 100}%`;
    } else {
      if (totalSteps === 0) {
        rectangle.style.width = "100%";
      } else {
        const progress = currentIndex / totalSteps;
        const minWidth = 1 / totalSteps;
        const maxWidth = 1 - minWidth;
        const width = minWidth + progress * maxWidth;
        rectangle.style.width = `${width * 100}%`;
      }
    }
  }

  function updateButtonStates() {
    if (window.innerWidth <= 988) {
      prevButton.disabled = tutorsGrid.scrollLeft === 0;
      nextButton.disabled =
        tutorsGrid.scrollLeft >=
        tutorsGrid.scrollWidth - tutorsGrid.clientWidth;
    } else {
      prevButton.disabled = currentIndex === 0;
      nextButton.disabled = currentIndex >= totalItems - visibleTutors;
    }
    prevButton.classList.toggle("disabled", prevButton.disabled);
    nextButton.classList.toggle("disabled", nextButton.disabled);
  }

  function init() {
    updateTutorsVisibility();
    updateButtonStates();
    updateRectangleWidth();
  }

  init();

  nextButton.addEventListener("click", function () {
    if (window.innerWidth <= 988) {
      tutorsGrid.scrollBy({ left: tutorsGrid.clientWidth, behavior: "smooth" });
    } else if (currentIndex < totalItems - visibleTutors) {
      currentIndex++;
      updateTutorsVisibility();
      updateButtonStates();
      updateRectangleWidth();
    }
  });

  prevButton.addEventListener("click", function () {
    if (window.innerWidth <= 988) {
      tutorsGrid.scrollBy({
        left: -tutorsGrid.clientWidth,
        behavior: "smooth",
      });
    } else if (currentIndex > 0) {
      currentIndex--;
      updateTutorsVisibility();
      updateButtonStates();
      updateRectangleWidth();
    }
  });

  tutorsGrid.addEventListener("scroll", function () {
    if (window.innerWidth <= 988) {
      updateRectangleWidth();
      updateButtonStates();
    }
  });

  window.addEventListener("resize", function () {
    visibleTutors = window.innerWidth <= 988 ? 2 : 3;
    totalSteps = Math.max(0, totalItems - visibleTutors);
    currentIndex = 0;
    init();
  });
}

export function addSubdomain(subdomain: string) {
  // Extract the protocol and domain

  if (process.env.REACT_APP_IBL_LMS_URL) {
    const [protocol, domain] = process.env.REACT_APP_IBL_LMS_URL.split("://");

    return `${protocol}://${subdomain}.${domain}`;
  }
  return subdomain;
}

export const getUserCourseCompletions = (
  callback: (data: userCourseCompletion) => void
) => {
  api.ibldmcourses.fetchCourseCompletions(
    { user_id: getUserId() },
    (data: any) => callback(data),
    (error: any) => console.error(error)
  );
};

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const ORDERED_CRED_LISTS = [
    'Why Lead', 'Leading Self (Courage)','Leading Together (Love)','Leading Change (Hope)','Leading Forward'
]

export function findImmediateNextChallengeCourse(currentCourseId: string) {
  // Find the index of the course with the given course ID
  const currentIndex = enabledCourseArray.findIndex(
    (course) => course.course_id === currentCourseId
  );

  // If the course isn't found or if it's the last course in the array, return null
  if (currentIndex === -1 || currentIndex === enabledCourseArray.length - 1) {
    return null;
  }

  // Check if the immediate next course has a type of "5-day-challenge"
  const nextCourse = enabledCourseArray[currentIndex + 1];
  if (nextCourse.type === "5-day-challenge") {
    return nextCourse;
  }

  // If the immediate next course is not a 5-day challenge, return null
  return null;
}

export function getFormattedDate() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date();

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export const BADGES_TOOLTIPS = [
  {
    label: "Master Reflector",
    description: "Issued when all rating scale questions have been completed",
  },
  {
    label: "To New Beginnings",
    description: "Issued when you start the Leading with Character course.",
  },
  {
    label: "The Coach",
    description:
      "Issued when you complete the Effective Questions quiz in the Leading Together (Love) module.",
  },
  {
    label: "The Watcher",
    description:
      "Issued when you have watched 100% of the videos in the course.",
  },
  {
    label: "The Quizmaster",
    description:
      "Issued once you have completed all quizzes across the course.",
  },
  {
    label: "The Hotspotter",
    description:
      "Issued when you have read all the hotspots within the course.",
  },
];

export const getContactMailTemplate = (innerContent: string) => {
  return `
                
                <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Leading with Character notification</title>
  <style>
      /* Basic reset */
      body, p, h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0; }
      body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.5; }

      /* Container styles */
      .container { max-width: 600px; margin: 0 auto; padding: 20px; }

      /* Header styles */
      .header { background-color: #0043A1; color: #fff; padding: 10px; width: 100%;justify-content: center;display: flex;align-items: center;}
      .header h1 { font-size: 24px; }

      /* Content styles */
      .content { padding: 20px 0; }
      .content p { margin-bottom: 10px; }

      /* Footer styles */
      .footer { background-color: #f2f2f2; padding: 10px; text-align: center; font-size: 14px; }
  </style>
</head>
<body>
<div class="container">
  <div class="header">
    <h1>Leading with Character</h1>
  </div>
  ${innerContent}
  <div class="footer">
    <p>This email was sent on ${getFormattedDate()}.</p>
  </div>
</div>
</body>
</html>`;
};
