import useHeaderFooter from "../../hooks/useHeaderFooter";
import './TermsOfUse.css'

const TermsOfUse = ({children}: {children: React.ReactNode}) => {
    const { headerContent, footerContent } = useHeaderFooter(children);
    return (
        <>
            <input type="hidden" id="anPageName" name="page" defaultValue="about" />
            <div className="container-center-horizontal">
                <div className="about screen contact">
                    <div className="homepage ">
                        {headerContent}
                        <div className="overlap-group6">
                            <div className="hero-image">
                                <div className="overlap-group-1">
                                    <div
                                        className="overlap-group-item"
                                        style={{
                                            backgroundImage: 'url("/img/legatum-terms-of-use-banner.jpg")',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            backgroundBlendMode: 'overlay',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        role="img"
                                        aria-label="Privacy policy background"
                                    />
                                    <h1 className="about-us recklessneue-regular-normal-white-100-1px">
                                        <span className="recklessneue-regular-normal-white-100-1px">
                                            Terms of Use{" "}
                                        </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="legatum-container the-course">
                        <div className="c-section">
                            <div className="leg-title">1. Acceptance of Terms</div>
                            <p className="leg-p">
                                By accessing and using this website, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please refrain from using the site.
                                {/*<ul style={{ margin: '5px' }}>
                                    <li style={{ margin: '5px' }}>basic information, such as your name (including name, prefix or title), the company you work for, your job title or position;</li>
                                    <li style={{ margin: '5px' }}>contact information, such as your email address and phone number;</li>
                                    <li style={{ margin: '5px' }}>financial information, such as payment-related information;</li>
                                    <li style={{ margin: '5px' }}>technical information, such as information from your visits to our website or applications, or in relation to materials and communications we send to you electronically;</li>
                                    <li style={{ margin: '5px' }}>responses to select course questions, such as your goals and achievements within the course, ways to improve the course, and ratings at the start and conclusion of each module;</li>
                                    <li style={{ margin: '5px' }}>any other information relating to you which you may provide to us.</li>
                                </ul>*/}
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">2. Use of Website</div>
                            <p className="leg-p">
                                You agree to use this website only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of this website by any third party.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">3. Intellectual Property</div>
                            <p className="leg-p">
                                All content on this website, including text, images, graphics, logos, and other materials, is the property of Legatum Limited or the University of Oxford or their licensors and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or otherwise use any content without prior written consent.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">4. User-Generated Content</div>
                            <p className="leg-p">
                                If you submit content to this website, you grant Legatum Limited a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display such content. You are solely responsible for ensuring that your submissions do not violate any laws or infringe on third-party rights.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">5. Disclaimers and Limitation of Liability</div>
                            <p className="leg-p">
                                This website is provided on an “as is” and “as available” basis. Legatum Limited makes no representation or warranty of any kind, express or implied: (i) as to the operation or availability of the website or any services offered thereon, or the information, content, and materials or products included thereon; (ii) that the website or any services offered thereon will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the website; or (iv) that the website, its servers, the content, or e-mails sent from or on behalf of Legatum Limited are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.<br/>
                                To the maximum extent permitted by applicable law, in no event shall Legatum Limited or its affiliates be liable for any special, incidental, indirect, or consequential damages whatsoever, including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the website, third-party software and/or third-party hardware used with the website, or otherwise in connection with any provision of these Terms of Use, even if Legatum Limited or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">6. Third-Party Links</div>
                            <p className="leg-p">
                                This website may contain links to third-party websites. Legatum Limited is not responsible for the content or practices of those sites and provides these links solely for your convenience.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">7. Modifications to Terms</div>
                            <p className="leg-p">
                                Legatum Limited reserves the right to update or modify these Terms of Use at any time without prior notice. Continued use of the website following changes constitutes acceptance of the updated terms.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">8. Governing Law</div>
                            <p className="leg-p">
                                These Terms of Use are governed by the laws of England and Wales, and any disputes will be resolved in the courts of England. If you have any concern or dispute related to the use of this website, you agree to first attempt to resolve the dispute informally by contacting Legatum at <a className={"mailto-link"} href={"mailto:support@leadingwithcharacter.com"}>support@leadingwithcharacter.com</a>.
                            </p>
                        </div>

                        <div className="c-section">
                            <div className="leg-title">9. Contact Us</div>
                            <p className="leg-p">
                                For any questions or concerns about these Terms of Use, please contact us at <a className={"mailto-link"} href={"mailto:support@leadingwithcharacter.com"}>support@leadingwithcharacter.com</a>.
                            </p>
                        </div>
                        
                    </div>

                    {footerContent}
                </div>
            </div>
            <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
                <section id="shadow-host-companion" />
            </div>
        </>

    )
}

export default TermsOfUse;