import React, {useContext, useEffect, useState} from 'react';
import {getUserName, AppDataContext} from "@iblai/ibl-web-react-common";
import {isLoggedIn} from "./utils/helpers";

export interface locationData{
    country_code:string;
    country_name:string;
    city:string;
    city_gps:{
        lat:number;
        lng:number;
    } | null
}

export interface formattedLocationData {
    latitude:number,
    longitude:number,
    user_count:number
    /*tooltip:{
        content:string
    }*/
}

const useUserLocation = () => {
    const [userHasRegisteredLocation, setHasUserRegisteredLocation] = useState(true)

   /* useEffect(() => {
        handleCheckIfUserHasRegisteredLocation()
    }, []);*/

    const handleCheckIfUserHasRegisteredLocation = (callback:(response:Response)=>void) =>{
        if(!isLoggedIn()){
            return
        }
        const url = `${process.env.REACT_APP_IBL_DM_URL}/api/career/location/orgs/main/users/${getUserName()}/`;
        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
        }).then(callback)
    }


    const handleSaveLocation = (data:locationData, successCallback=()=>{}, errorCallback=()=>{}) => {
        const url = `${process.env.REACT_APP_IBL_DM_URL}/api/career/location/orgs/main/users/${getUserName()}/`;
        fetch(url, {
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + localStorage.getItem('dm_token'),
            },
            body:JSON.stringify(data)
        }).then((response)=>{
            if(response.ok){
                successCallback()
            }else{
                errorCallback()
            }

        }).catch(()=>{
            errorCallback()
        })
    }

    const handleFetchAllUserLocationData = (callback:(response:Response)=>void) => {
        const url = `${process.env.REACT_APP_IBL_DM_URL}/api/career/locations/orgs/main/`;
        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(callback)
    }



    return {handleCheckIfUserHasRegisteredLocation, handleSaveLocation, handleFetchAllUserLocationData}
};

export default useUserLocation;